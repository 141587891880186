@font-face {
  font-family: "Alexandria-Bold";
  src: url("./assets/fonts/Alexandria-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Alexandria-Medium";
  src: url("./assets/fonts/Alexandria-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Alexandria-Regular";
  src: url("./assets/fonts/Alexandria-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-Regular";
  src: url("./assets/fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-Medium";
  src: url("./assets/fonts/Lato-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-Bold";
  src: url("./assets/fonts/Lato-Bold.ttf") format("truetype");
}

:root {
  --bg-color: #ffffff;
  --main-color: #000000;
  --second-color: #212121;
  --third-color: #e5e5e5;
  --main-text: #333333;
  --second-text: #4f4f4f;
  --font-regular: "Lato-Regular";
  --font-medium: "Lato-Medium";
  --font-bold: "Lato-Bold";
}

[dir="rtl"]:root {
  --font-regular: "Alexandria-Regular";
  --font-medium: "Alexandria-Medium";
  --font-bold: "Alexandria-Bold";
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #a7a7a7;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--bg-color);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-regular);
}

html {
  direction: ltr;
  scroll-behavior: smooth;
}

[dir="rtl"] html {
  direction: rtl;
}

body {
  min-height: 100vh;
  width: 100vw;
  background: var(--bg-color);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: ltr;
  font-size: 16px;
  line-height: 1.5;
  color: var(--main-text);
  font-family: var(--font-regular);
  font-weight: 400;
  text-align: left;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
}

[dir="rtl"] body {
  direction: rtl;
  text-align: right;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
}

button {
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}

input {
  border: none;
  outline: none;
  box-shadow: none;
}

.animated-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: zoomInOut 10.5s ease-in-out;
}

@keyframes zoomInOut {
  0% {
    transform: scale(0);
  }

  20% {
    transform: scale(1);
  }

  80% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

.container {
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
}

.title {
  text-align: start;
  font-size: 52px;
  margin-bottom: 15px;
  text-transform: uppercase;
  width: 100%;
  font-family: var(--font-bold);
  line-height: 1.5;
  color: #000;
}

.desc {
  text-align: start;
  font-size: 18px;
  width: 100%;
  font-family: var(--font-medium);
  margin-bottom: 25px;
  line-height: 2;
  color: #000;
}

.dark-text {
  color: #000;
}

.white-text {
  color: #fff;
}

.main-btn {
  padding: 10px 25px;
  font-size: 16px;
  text-transform: capitalize;
  color: #fff !important;
  font-family: var(--font-bold);
  background: var(--main-color) !important;
  box-shadow: 0 0 3px 0 #ffffff33;
  border: 1px solid transparent;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.main-btn::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff33;
  top: 0;
  left: 0;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
}

.main-btn:hover::before {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
}

.main-btn:hover {
  box-shadow: 0 0 3px 0 #ffffff66;
}

:disabled {
  cursor: not-allowed !important;
  opacity: 0.5 !important;
}

@media (max-width: 1200px) {
  .container {
    max-width: 992px;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 768px;
  }

  .title {
    font-size: 36px;
  }

  .mouse-cursor * {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 576px;
  }

  .typed {
    font-size: 60px;
  }
}

@media (max-width: 576px) {
  .container {
    max-width: 100%;
  }
}
