.header {
  position: fixed;
  top: 25px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  height: 65px;
  background: #fff;
  box-shadow: 0 0 3px 0 #00000033;
  z-index: 101;
}

.header.small-header {
  background: #000000;
  top: 0;
  height: 25px;
}

.header.small-header li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.header.small-header li a i {
  font-size: 12px;
}

.header.small-header li a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #ffffff;
  font-size: 12px;
}

[lang="ar"] .header.small-header li a {
  direction: ltr;
}

.header ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.header ul li:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.header ul .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 55px;
}

.header ul .logo svg {
  width: 100%;
  height: 100%;
}

.header ul li .menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 60px;
  cursor: pointer;
}

.header ul li .menu svg {
  width: 100%;
  height: 100%;
}

.header ul li .nav-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  gap: 30px;
}

.header ul li .nav-links li {
  height: 100%;
  cursor: pointer;
}

.header ul li .nav-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  padding: 0px 10px;
  transition: all 0.3s ease;
}

.header ul li .nav-links a.active {
  color: var(--main-color);
  font-family: var(--font-bold);
  font-weight: 700;
}

.header .has-menu {
  position: relative;
}

.header .has-menu::after {
  content: "";
  position: absolute;
  top: -100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  transition: all 0.3s ease;
  opacity: 0;
}

.header .has-menu:hover::after {
  opacity: 1;
  top: 100%;
}

.header .has-menu .menu {
  position: fixed;
  top: -250px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 210px;
  background: #fff;
  padding: 20px 0;
  border-radius: 0 0 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px 0 #00000033;
  transition: all 0.3s ease;
  overflow: hidden;
  z-index: -1;
}

.header .has-menu:hover .menu {
  top: 60px;
  cursor: default;
}

.header .has-menu .menu ul {
  gap: 15px;
  height: 100%;
}

.header .has-menu .menu ul li {
  width: calc(100% / 3 - 10px);
  overflow: hidden;
}

.header .has-menu .menu ul li a {
  position: relative;
  justify-content: flex-start;
  height: auto;
  color: var(--main-color);
  font-size: 16px;
  padding: 0px;
  transition: all 0.3s ease;
  line-height: 2;
  padding-left: 20px;
}

.header .has-menu .menu ul li a::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-bottom: 5px solid transparent;
  border-left: 5px solid var(--main-color);
  border-top: 5px solid transparent;
  transition: all 0.3s ease;
  opacity: 1;
}

.header .has-menu .menu ul li a:hover {
  color: var(--second-color);
}

.header .has-menu .menu ul li a:hover::before {
  left: -5px;
}

.header .has-menu .menu ul li h4 a {
  font-size: 20px;
  color: var(--text-color);
  margin-bottom: 10px;
  font-family: var(--font-bold);
  font-weight: 700;
  padding: 0;
  line-height: 1.5;
}

.header .has-menu .menu ul li h4 a::before {
  display: none;
}

.header .has-menu .menu ul li img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.header .menu-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  width: 35px;
  height: 50px;
  cursor: pointer;
  background: transparent;
}

.header .menu-btn span {
  display: block;
  width: 100%;
  height: 3px;
  background: #000;
  border-radius: 50px;
  margin: 4px 0;
  transition: all 0.3s ease;
}

.header .menu-btn svg {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

@media (max-width: 992px) {
  .header {
    background: #ffffff;
  }

  .header.small-header li:last-child {
    display: none;
  }

  .header.small-header ul li {
    width: 100%;
    justify-content: space-between;
  }

  .header .menu-btn {
    /* display: flex; */
    /* flex-direction: column; */
  }

  .header ul li .main-btn,
  .header ul li .nav-links {
    display: none;
  }

  .header ul li .nav-links.show {
    display: flex;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background: #ffffff;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 30px;
    z-index: 999;
  }

  .header ul li .nav-links.show li {
    width: 100%;
    height: 60px;
  }

  .header ul li .nav-links.show li .menu,
  .header .has-menu::after {
    display: none;
  }

  .header .has-menu .menu ul li a {
    color: var(--text-color);
  }

  .header .has-menu .menu ul li h4 a {
    color: var(--main-color);
  }

  .header ul li .nav-links a {
    color: var(--main-color);
    font-weight: 700;
    font-size: 18px;
  }
}

.lang-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #ffffff;
  font-size: 10px;
  padding: 5px 20px;
  line-height: 1.5;
  border-radius: 0;
  background: #2d2d2d75;
  height: 65px;
  cursor: pointer;
}

.lang-btn i {
  font-size: 10px;
}
