.footer {
  padding: 25px 0px;
  width: 100%;
  height: auto;
  text-align: center;
  background: linear-gradient(90deg, #00000050 10%, transparent 100%);
  background: -webkit-linear-gradient(90deg, #00000050 10%, transparent 100%);
  z-index: 101;
}

.footer .footer-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 25px;
  padding: 50px 0px;
}

.footer .footer-grid .footer-item {
  display: flex;
  flex-direction: column;
}

.footer .footer-grid .footer-item svg {
  width: 100px;
  height: 75px;
  object-fit: contain;
  margin-bottom: 15px;
}

.footer .footer-grid .footer-item svg * {
  fill: #fff;
}

.footer .footer-grid .footer-item h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 15px;
}

.footer .footer-grid .footer-item h4 {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 15px;
}

.footer .footer-grid .footer-item p {
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  margin-bottom: 15px;
}

.footer .footer-grid .footer-item a {
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  margin-bottom: 15px;
}

@media (max-width: 992px) {
  .footer .footer-grid {
    grid-template-columns: repeat(2, 1fr);
    padding: 0px;
  }

  .footer .footer-grid .footer-item {
    padding: 0px 15px;
  }

  .footer .footer-grid .footer-item:nth-child(2) {
    padding-top: 60px;
  }

  .footer .footer-grid .footer-item svg {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-bottom: 15px;
  }

  .footer .footer-grid .footer-item h3 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .footer .footer-grid .footer-item h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .footer .footer-grid .footer-item p {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 15px;
  }

  .footer .footer-grid .footer-item a {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 15px;
  }
}
