.cv-page {
  width: 100%;
  border: unset;
  padding: 0;
  height: 100vh;
  min-height: 100vh;
  margin: 100px 0px;
}

@media (max-width: 1200px) {
  .cv-page {
    width: 100%;
    height: auto;
  }
}