.home {
  position: relative;
  min-height: 100svh;
  overflow: hidden;
}

.hero-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  /* background-image: url("../../assets/images/global/about-bg.jpg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
}

.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000ab;
  z-index: 1;
}

.hero-section video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.hero-section .hero-content {
  position: absolute;
  width: 100%;
  z-index: 2;
}

.hero-section .hero-content h1 {
  text-align: center;
  margin-bottom: 25px;
}

.hero-section .hero-content h1 .typed {
  font-size: 42px;
  text-align: center;
  background: #00000050;
  padding: 5px 10px;
}

.hero-section .hero-content h1 .typed span {
  color: #fff;
}

.hero-section .hero-content h1 .typed span:first-of-type {
  color: var(--main-color);
  font-family: var(--font-bold);
  font-size: 42px;
  text-transform: uppercase;
  background: linear-gradient(118deg, #fff 0%, #fff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animateText 3s linear infinite;
  background-size: 200% 100%;
  -webkit-animation: animateText 3s linear infinite;
}

@keyframes animateText {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.hero-section .hero-content p {
  color: #fff;
  text-align: center;
  font-size: 22px;
  line-height: 2;
  font-family: var(--font-medium);
  text-transform: capitalize;
  width: 75%;
  margin: auto;
}

.hero-section .hero-content .start-btn {
  margin-top: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-section .hero-content .start-btn button {
  padding: 10px 25px;
  font-size: 16px;
  text-transform: capitalize;
  color: #000000;
  font-family: var(--font-bold);
  background: #fff;
  box-shadow: 0 0 3px 0 #ffffff33;
  border: 1px solid transparent;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.hero-section .hero-content .start-btn button:hover {
  color: transparent;
}

.hero-section .hero-content .start-btn button::before {
  content: "Start Now";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-bold);
  transition: all 0.3s ease-in-out;
  transform: translateY(100%);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
}

[dir="rtl"] .hero-section .hero-content .start-btn button::before {
  content: "ابدأ الآن";
}

.hero-section .hero-content .start-btn button:hover::before {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
}

.services-section {
  padding: 100px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

.services-section .services-swiper {
  width: 50%;
}

.services-section .services-swiper .swiper {
  width: 100%;
}

[dir="rtl"] .services-section .services-swiper .swiper {
  direction: ltr;
}

.services-section .services-swiper .swiper .swiper-wrapper {
  padding-block: 50px;
}

.services-section .services-swiper .swiper .swiper-pagination-bullet-active {
  background: var(--main-color);
}

.services-section .services-swiper .services-card {
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

[dir="rtl"] .services-section .services-swiper .services-card {
  direction: rtl;
}

.services-section .services-swiper .services-card .services-img {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ddd;
  overflow: hidden;
  margin-bottom: 15px;
  transition: all 0.3s ease-in-out;
}

.services-section .services-swiper .services-card .services-img svg {
  width: 25px;
  height: 25px;
}

.services-section .services-swiper .services-card:hover .services-img {
  background: #000000;
}

.services-section .services-swiper .services-card:hover .services-img svg * {
  fill: #fff;
}

.services-section .services-swiper .services-card .services-content h4 {
  font-size: 20px;
  margin-bottom: 15px;
  font-family: var(--font-bold);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
}

.services-section .services-swiper .services-card .services-content p {
  font-size: 16px;
  line-height: 2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.services-section .services-info {
  width: 50%;
}

.about-bg {
  background: #000000;
}

.about-section {
  padding: 100px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 50px;
}

.about-section .about-collapse .ant-collapse-header {
  align-items: center;
}

.about-section .about-collapse .ant-collapse-expand-icon svg {
  fill: #fff;
}

.about-section .about-collapse .ant-collapse-content {
  background-color: transparent;
}

.about-section .about-collapse .ant-collapse-content p {
  color: #fff;
  font-size: 16px;
  line-height: 2;
  font-family: var(--font-medium);
}

.about-section .about-collapse .title-bar {
  display: flex;
  align-items: center;
  gap: 20px;
}

.about-section .about-collapse .title-bar span {
  font-size: 22px;
  font-family: var(--font-bold);
  text-transform: uppercase;
  color: #fff;
}

.about-section .about-collapse .title-bar svg {
  width: 35px;
  height: 30px;
}

.about-section .about-collapse .title-bar svg * {
  fill: #fff;
}

@media (max-width: 992px) {
  .hero-section .hero-content h1 .typed span:first-of-type {
    font-size: 36px;
  }

  .hero-section .hero-content p {
    font-size: 18px;
    width: 100%;
  }

  .services-section {
    flex-direction: column;
    padding: 50px 0;
  }

  .services-section .services-swiper {
    width: 100%;
  }

  .services-section .services-info {
    width: 100%;
  }

  .about-section {
    grid-template-columns: repeat(1, 1fr);
    padding: 50px 0;
  }

  .about-section .about-collapse .title-bar span {
    font-size: 20px;
  }

  .about-section .about-collapse .title-bar svg {
    width: 30px;
    height: 25px;
  }

  .about-section .about-collapse .ant-collapse-header {
    align-items: flex-start;
  }

  .about-section .about-collapse .ant-collapse-content p {
    font-size: 16px;
  }

  .about-section .about-collapse .ant-collapse-expand-icon svg {
    width: 20px;
    height: 20px;
  }

  .about-section .about-collapse .ant-collapse-content {
    padding: 0;
  }

  .about-section .about-collapse .ant-collapse-content p {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
}
