.contact-section {
  padding: 100px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 50px;
}

.contact-section .contact-form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 30px;
}

.contact-section .contact-form .form-input {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  background-color: transparent;
  width: 100%;
}

.contact-section .contact-form .form-input.w-50 {
  width: calc(50% - 15px);
}

.contact-section .contact-form .form-input.w-100 {
  width: 100%;
}

.contact-section .contact-form label {
  font-size: 18px;
  color: #ccc;
  background-color: transparent;
  font-family: var(--font-medium);
  position: absolute;
  top: 12.5px;
  left: 15px;
  font-size: 16px;
  color: #ddd;
  transition: all 0.3s ease;
  text-transform: capitalize;
  pointer-events: none;
}

[dir="rtl"] .contact-section .contact-form label {
  right: 15px;
  left: none;
}

.contact-section .contact-form input {
  width: 100%;
  height: 50px;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0 20px;
  font-size: 16px;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-family: var(--font-regular);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.contact-section .contact-form textarea {
  width: 100%;
  height: 80px;
  border: none;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: transparent;
  padding: 20px;
  font-size: 16px;
  color: #000;
  font-family: var(--font-regular);
}

.contact-section .contact-form .form-input:focus label,
.contact-section .contact-form .form-input:focus-within label {
  top: -25px;
  left: 5px;
  font-size: 14px;
  color: var(--second-color);
}

.contact-section .contact-form .form-input input:focus,
.contact-section .contact-form .form-input textarea:focus {
  border: 1px solid var(--second-color);
}

.contact-section .contact-form .form-input input:valid,
.contact-section .contact-form .form-input textarea:valid {
  border: 1px solid var(--second-color);
}

.contact-section .contact-form .form-input input:valid + label,
.contact-section .contact-form .form-input textarea:valid + label {
  top: -25px;
  left: 5px;
  font-size: 14px;
  color: var(--second-color);
}

.contact-section .contact-form button {
  width: 100%;
}

[dir="rtl"] .contact-section .contact-form button {
  letter-spacing: 0px;
}

.alert-success{
  width: 100%;
  text-align: center;
  display: block;
  padding: 10px 20px;
  background-color: #d4edda;
  color: #155724;
  border-radius: 10px;
  margin-top: 20px;
}

@media (max-width: 992px) {
  .contact-section {
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    padding: 50px 0;
  }
  .contact-section .contact-form .form-input.w-50 {
    width: 100%;
  }
}
